import classNames from 'classnames';
import { ButtonHTMLAttributes, FC } from 'react';
import styles from './Button.module.scss';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'contained' | 'outlined' | 'text';
};

const Button: FC<Props> = ({ className, variant = 'contained', ...otherProps }) => {
  return (
    <button className={classNames(styles.button, styles[variant], className)} {...otherProps} />
  )
}

export default Button;