import { onlineManager, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';
import Icon from './components/basics/Icon/Icon';
import IncompleteLoadMessage from './components/elements/IncompleteLoadMessage/IncompleteLoadMessage';
import QueryOptionsPane from './components/elements/QueryOptionsPane/QueryOptionsPane';
import QueryPropertiesPane from './components/elements/QueryPropertiesPane/QueryPropertiesPane';
import QuerySourcePane from './components/elements/QuerySourcePane/QuerySourcePane';
import { usePersistentQueryOptionsStore } from './state/stores/usePersistentQueryOptionsStore';
import { usePersistentQuerySourceStore } from './state/stores/usePersistentQuerySourceStore';

interface Props {};

const App: FC<Props> = () => {
  const country = usePersistentQuerySourceStore(state => state.country);
  const queryOptions = usePersistentQueryOptionsStore(({ changeOption: _, ...options}) => options);
  const [online, setOnline] = useState(onlineManager.isOnline());
  const [activePane, setPane] = useState<string>('query-properties')
  
  const query = useQuery<any, Error>({
    ...queryOptions,
    queryKey: ['universities', { country }],
    queryFn: async () => (await axios('http://universities.hipolabs.com/search', {
      params: {
        country: country,
      }
    })).data,
  })
  useEffect(() => {
    return onlineManager.subscribe(setOnline);
  }, [])
  
  const panes: Record<string, any> = {
    'query-sources': {
      'label': 'Data source',
      'icon': 'database-line',
      'component': <QuerySourcePane query={query} />,
    },
    'query-properties': {
      'label': 'Query properties',
      'icon': 'list-check',
      'component': <QueryPropertiesPane query={query} />,
    },
    'query-options': {
      'label': 'Query options',
      'icon': 'equalizer-3-line',
      'component': <QueryOptionsPane />,
    }
  }
  
  const initialLoadIncomplete = query.status === 'pending' && queryOptions.enabled === false;
  
  return (
    <div className="grid grid-cols-12 h-full">
      <div className="h-full overflow-scroll col-span-8 border-r border-gray-300">
        {initialLoadIncomplete && <IncompleteLoadMessage />}
        <ul className="list list-disc list-inside pl-4">
          {query.data?.map((university: any) => (
            <li key={university.name}>{university.name}</li>
          ))}
        </ul>
      </div>
      <div className="h-full overflow-scroll col-span-4 bg-white">
        <div className="px-8 pt-8 pb-4">
          <div className="flex items-start gap-6">
            <div>
              <h1 className="mb-2">Persistent React Query demo</h1>
              <p><small>This demo application demonstrates how React Query can be set-up to be persistent.</small></p>
            </div>
            <div className="w-fit ml-auto flex gap-3 items-center bg-white py-2 pl-4 pr-3 rounded-full border border-gray-300">
              <h5>{ online ? 'Online' : 'Offline' }</h5>
              <div className={classNames('w-3 h-3 rounded-full', online ? 'bg-green-500' : 'bg-red-500')} />
            </div>
          </div>
        </div>
        <div className="border-b border-gray-300 px-8 flex gap-x-4 pt-2 bg-white sticky top-0">
          { Object.entries(panes).map(([key, pane]) => (
            <button 
              key={key} 
              onClick={() => setPane(key)}
              className={classNames('flex items-center gap-2 p-0 pb-2 border-b border-solid rounded-none', activePane === key ? 'border-black' : 'border-transparent')}
            >
              <Icon name={pane.icon} size="small" />
              <span>{ pane.label }</span>
            </button>
          ))}
        </div>
        <div className="pt-4">
          { panes[activePane].component }
        </div>
      </div>
    </div>
  )
}

export default App;