import { ChangeEvent, FC } from 'react';
import { usePersistentQueryOptionsStore } from '../../../state/stores/usePersistentQueryOptionsStore';

interface Props {};

const refetchConditions = {
  refetchOnMount: 'when component is mounted',
  refetchOnWindowFocus: 'when window is focused',
  refetchOnReconnect: 'when internet connection is restored'
}
const refetchValue = {
  'true': 'When stale',
  'false': 'Do not refresh',
  'always': 'Always'
};

const QueryOptionsPane: FC<Props> = () => {
  const queryOptions = usePersistentQueryOptionsStore(store => store);
  const setQueryOption = usePersistentQueryOptionsStore(store => store.changeOption);
  
  const handleQueryEnableInputChange = (event: ChangeEvent<HTMLInputElement>) => setQueryOption('enabled', event.target.checked);
  const bindRefetchConditionChangeHandler = (condition: string) => {
    return (event: ChangeEvent<HTMLSelectElement>) => {
      setQueryOption(condition as any, event.target.value as any);
    }
  }
    
  return (
    <div className="px-8">
      <h4>Configure the query behaviour</h4>
      <div className="content">
        <div className="divide-y divide-gray-300 *:py-4">
          <div className="flex items-center">
            <div className="flex-1">
              <h4>enabled</h4>
              <p>Manually enable/disable query execution</p>
            </div>
            <input type="checkbox" checked={queryOptions.enabled} onChange={handleQueryEnableInputChange} />
          </div>
          { Object.entries(refetchConditions).map(([refetchCondition, condtionInfo]) => (
            <div key={refetchCondition}>
              <div className="flex items-center gap-4">
                <div className="flex-1">
                  <h4>{ refetchCondition }</h4>
                  <p className="text-sm">Refresh data { condtionInfo }</p>
                </div>
                <select name={refetchCondition} value={String((queryOptions as any)[refetchCondition])} onChange={bindRefetchConditionChangeHandler(refetchCondition)}>
                  { Object.entries(refetchValue).map(([key, value]) => (
                    <option value={key}>{ value }</option>
                  ))}
                </select>
              </div>
              { queryOptions.enabled === false && (
                <div className="bg-amber-100 py-2 px-3 rounded-md mt-2">
                  <p className="text-sm font-medium text-amber-700">Disabled by the `enabled` property.</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default QueryOptionsPane;