import { FC } from 'react';
import Icon from '../../basics/Icon/Icon';

interface Props {};

const IncompleteLoadMessage: FC<Props> = () => {
  return (
    <div className="flex items-center justify-center h-full">
      <div className="flex flex-col items-center justify-center bg-white py-6 px-10 shadow-md rounded-lg">
        <Icon name="alert-line" size="medium" />
        <div className="mt-2 text-center">
          <h4>Querying is disabled</h4>
          <p className="headline">Enable query to load data</p>
        </div>
      </div>
    </div>
  )
}

export default IncompleteLoadMessage;