import { QueryObserverOptions } from "@tanstack/react-query";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

interface UsePersistentQueryOptionsStore 
  extends Partial<QueryObserverOptions> {
  changeOption: <T extends keyof QueryObserverOptions>(key: T, value: QueryObserverOptions[T]) => void;
}

const defaultValues = {
  enabled: true,
}

export const usePersistentQueryOptionsStore = create(
  persist<UsePersistentQueryOptionsStore>(
    set => ({
      ...defaultValues,
      changeOption: (key, value) => {
        set({
          [key]: value
        })
      },
    }),
    {
      name: "queryOptionsStore", // unique name
      storage: createJSONStorage(() => window.localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);
