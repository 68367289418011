import { ChangeEvent, FC } from 'react';
import { usePersistentQuerySourceStore } from '../../../state/stores/usePersistentQuerySourceStore';
import { queryClient } from '../../../utils/vendors/tanstack';
import Button from '../../basics/Button/Button';

interface Props {
  query?: any;
};

const QuerySourcePane: FC<Props> = ({ query }) => {
  const country = usePersistentQuerySourceStore(state => state.country);
  const setCountry = usePersistentQuerySourceStore(state => state.setCountry);
  
  const handleCountrySwitch = (event: ChangeEvent<HTMLSelectElement>) => {
    setCountry(event.target.value);
  }
  
  return (
    <div className="px-8">
      <div className="mb-4">
        <h4>The data source that is used for this demo</h4>
        <p className="headline">Currently this is the universities API from Hipolabs.</p>
      </div>
      <div className="flex flex-col gap-4">
        <div>
          <h5>Select country</h5>
          <select value={country} onChange={handleCountrySwitch} className="block w-full bg-white">
            <option value="belgium">Belgium</option>
            <option value="netherlands">Netherlands</option>
            <option value="france">France</option>
            <option value="germany">Germany</option>
          </select>
        </div>
        <div className="flex justify-between gap-2">
          <Button disabled={query.isFetching} onClick={() => query.refetch()}>Refetch { country }</Button>
          <div className="flex gap-2">
            <Button onClick={() => queryClient.clear()} variant="outlined">Clear queries</Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default QuerySourcePane;