import classNames from 'classnames';
import { FC, Fragment, useState } from 'react';
import { Popover } from 'react-tiny-popover';

interface Props {
  query?: any;
};

const QueryPropertiesPane: FC<Props> = ({ query }) => {
  const [helpPopoverIndex, setHelpPopoverIndex] = useState<undefined | number>(undefined);

  const trackedProperties = [
    { name: 'Status', value: query.status },
    { name: 'Loading', value: query.isLoading ? 'Loading' : 'Ready', help: {
      info: 'Indicates whether if there\'s no cached data and no query attempt was finished yet.'
    }},
    { name: 'Fetching', value: query.isFetching ? 'Fetching' : 'Data fetched', help: {
      info: 'Indicates whether the queryFn is executing, which includes initial loading as well as background refetches'
    }},
    { name: 'Error', value: query.isError ? 'Has error' : 'No errors', help: {
      info: 'Indicates whether the query attempt resulted in an error. The corresponding error property has the error received from the attempted fetch.'
    }},
  ]
  
  const filteredProperties = Object.entries(query).filter(([, value]) => {
    const acceptedTypes = ['string', 'number', 'boolean'];
    return acceptedTypes.includes(typeof value) || value === null || value === undefined;
  });
  
  return (
    <div className="px-8">
      <h4>Displays the properties that are returned by a query</h4>
      <p className="headline">
        <a rel="noreferrer" target="_blank" href="https://tanstack.com/query/v4/docs/framework/react/reference/useQuery">Learn more</a> about these properties
      </p>
      <div className="flex flex-col divide-y divide-gray-300 py-1 *:py-4">
        { trackedProperties.map((trackedProperty, index) => (
          <div className="flex items-center">
            <div className="flex-1">
              <h4>{ trackedProperty.name }</h4>
              <p>{ trackedProperty.value }</p>
            </div>
            { trackedProperty.help && (
              <Popover
                isOpen={index === helpPopoverIndex}
                positions={['left', 'top']} // preferred positions by priority
                content={<div className="max-w-[400px] bg-gray-100 p-4 rounded-lg border-gray-300 border">
                  <h5 className="text-gray-700">{ trackedProperty.name }</h5>
                  <p className="text-sm">{ trackedProperty.help?.info }</p>
                </div>}
                onClickOutside={() => setHelpPopoverIndex(undefined)}
                        
              >
                <button 
                  onClick={() => setHelpPopoverIndex(helpPopoverIndex === index ? undefined : index)} 
                  className="bg-transparent text-gray-700 hover:bg-gray-100"
                >?</button>
              </Popover>
            )}
          </div>
        ))}
      </div>
      <div className="border-t border-gray-300 py-2 flex">
        <dl className="w-full grid grid-cols-2">
          { filteredProperties.map(([key, value]) => (
            <Fragment key={key}>
              <dt className="col-span-1 text-sm border-b border-gray-300 py-2 font-medium">{ key }</dt>
              <dd 
                className={classNames(
                  'col-span-1 text-sm border-b border-gray-300 py-2', 
                  {
                    'text-gray-400': value == null || value == undefined,                
                  }
                )}
              >{ String(value) }</dd>
            </Fragment>
          ))}
        </dl>
      </div>
    </div>
  )
}

export default QueryPropertiesPane;