import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

interface UsePersistentQuerySourceStoreInterface {
  country: string;
  setCountry: (country: string) => void;
}

export const usePersistentQuerySourceStore = create(
  persist<UsePersistentQuerySourceStoreInterface>(
    set => ({
      country: 'belgium',
      setCountry: (country: string) => set({ country }),
    }),
    {
      name: "querySourceStore", // unique name
      storage: createJSONStorage(() => window.localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);
